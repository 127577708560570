import React from 'react'
import Layout from '../components/layout'
import QrCodeGenerator from '../components/qr-code/QrCodeGenerator'
import Footer from '../components/footer'
import Helmet from 'react-helmet'

const QrCodeForm = () => {
  return (
    <Layout>
      <Helmet bodyAttributes={{
        class: 'body-no-padding'
      }}
      >
        <title>Free URL Shortener</title>
        <meta
          content='Need to shorten a URL? Use RB.GY for free, memorable shortened links that are easy to track. Ideal for social media, emails, and ads.'
          name='description'
        />
        <meta
          content='Free link shortener: Fast & simple short URLs'
          property='og:title'
        />
        <meta
          content='Need to shorten a URL? Use RB.GY for free, memorable shortened links that are easy to track. Ideal for social media, emails, and ads.'
          property='og:description'
        />
        <meta property='og:image' content='https://free-url-shortener.rb.gy/url-shortener.png' />
        <meta property='og:url' content='https://free-url-shortener.rb.gy' />
        <meta property='og:type' content='website' />
        <meta content='summary' name='twitter:card' />
        <meta content='width=device-width, initial-scale=1' name='viewport' />
        <link href='images/rebrandly-webclip.png' rel='apple-touch-icon' />
      </Helmet>
      <QrCodeGenerator />
      <Footer />
    </Layout>
  )
}

export default QrCodeForm
